<template>
    <div id="doc_preview_modal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
        <div class="modal-dialog" :class="{
                    'modal-xl': current === 'pdf',
                    'modal-lg': current === 'png',
            }">
            <div class="modal-content border-0">
                <div class="modal-header border-bottom p-3">
                    <h5 class="modal-title">
                        Document Preview
                    </h5>
                    <button @click="downloadFile(file_url)"
                            type="button" class="btn btn-primary">Download
                    </button>
                </div>
                <div class="p-4 text-dark pt-3">
                    <iframe v-if="current === 'pdf'" :src="pdf_url" width="100%" height="500px"></iframe>
                    <img v-if="current === 'png'" :src="file_url" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "DocPreview",
    props: {
        file_url: String,
    },
    data() {
        return {
            pdf_url: '',
            current: 'png' // or 'pdf'
        }
    },
    methods: {
        async downloadFile(url) {
            fetch(url)
                .then(resp => resp.blob())
                .then(blobobject => {
                    const blob = window.URL.createObjectURL(blobobject);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blob;
                    anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blob);
                })
                .catch(() => alert('An error in downloading the file sorry'));
        },
        getPdfFile(url) {
            axios.get(url, {
                responseType: 'arraybuffer'
            }).then(response => {
                const blob = new Blob([response.data], {type: 'application/pdf'});
                this.pdf_url = URL.createObjectURL(blob);
            }).catch(error => {
                console.log("error", error)
            });
        }
    },
    watch: {
        file_url(newVal) {
            if (newVal.endsWith('.pdf')) {
                this.current = 'pdf'
                this.getPdfFile(newVal)
            } else {
                this.current = 'png'
            }
        }
    }
}
</script>

<style scoped>

</style>