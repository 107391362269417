<script>
import {defineComponent} from 'vue'
import CustomTable from "@/components/tables/table.vue";
import CounterpartyApi from "@/api/counterparty/CounterpartyApi";
import DocPreviewModal from "@/views/pages/smgs/modal/DocPreview.vue";

export default defineComponent({
  name: "Single",
  components: {DocPreviewModal, CustomTable},
  data() {
    return {
      table_url: '/smgs/list/',
      table_headers: [
        {
          label: 'CODE',
          field: 'code',
          align: 'center',
        },
        {
          label: 'DOCUMENT',
          field: 'document',
          align: 'center',
        },
        {
          label: 'SMGS NUMBER',
          field: 'number',
          align: 'center',
        },
        {
          label: 'SMGS DATE',
          field: 'date',
          align: 'center',
          searchType: 'date',
        },
        {
          label: 'CONTAINER',
          field: 'container_name',
          align: 'center',
        },
        {
          label: 'WAGON',
          field: 'wagon_name',
          align: 'center',
        },
        {
          label: 'IMAGE',
          field: 'image_path',
          align: 'center',
          searchable: false
        },
        {
          label: 'FILLED CODE',
          field: 'is_sync',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Filled', value: 'true'},
            {label: 'Not Filled', value: 'false'},
          ],
        },
        {
          label: 'DEPARTURE',
          field: 'departure_station_name',
          align: 'center',
          visible: false
        },
        {
          label: 'DESTINATION',
          field: 'destination_station_name',
          align: 'center',
          visible: false
        },
        {
          label: 'WEIGHT',
          field: 'weight',
          align: 'center',
          visible: false
        },
      ],
      forwarders: [],

      file_url: '',
      order_service_url: process.env.VUE_APP_ORDER_URL + '/',
    }
  },
  methods: {
    async getForwarders() {
      let api = new CounterpartyApi()
      let response = await api.getCounterparties('?is_used_for_code=true')
      this.forwarders = response.results.map(forwarder => {
        return {
          value: forwarder.id,
          label: forwarder.name
        }
      })
    },
  }
})
</script>

<template>
  <DocPreviewModal :file_url="file_url"/>

  <CustomTable
      :url="table_url"
      name="Single page SMGS"
      id="documents"
      :headers="table_headers"
      :searchable="true"
      :selectable="true"
  >
    <template v-slot:application_number="slotProps">
      <h5 class="fw-medium link-primary my-0">
                              <span class="badge badge-soft-primary">
                                {{ slotProps.row.application_number }}
                              </span>
      </h5>
    </template>

    <template v-slot:forwarder_id="slotProps">
      <span v-if="slotProps.row.forwarder === null" class="text-danger">--</span>
      <span v-else>{{
          forwarders.filter(i => i.value === slotProps.row.forwarder_id)[0]['label']
        }}</span>
    </template>

    <template v-slot:image_path="slotProps">
      <span v-if="slotProps.row.image_path === null" class="text-danger">-</span>

      <span v-if="slotProps.row.image_path !== null"
            data-bs-toggle="modal"
            data-bs-target="#doc_preview_modal"
            @click="file_url= order_service_url+slotProps.row.image_path" class="text-danger">
              <font-awesome-icon
                  icon="fa-solid fa-image"
                  class="c_icon_hoverable text-secondary"
              />
            </span>
    </template>

    <template v-slot:is_sync="slotProps">
      <h6 class="my-0">
              <span class="badge" :class="{
                'badge-outline-success': slotProps.row.is_sync,
                'badge-outline-warning': !slotProps.row.is_sync,
              }">
                {{ slotProps.row.is_sync ? 'FILLED' : 'NOT FILLED' }}
              </span>
      </h6>
    </template>

    <template v-slot:departure_station="slotProps">
      <span v-if="slotProps.row.departure_station === null" class="text-danger">--</span>
      <span v-else>{{
          slotProps.row.departure_station.name + '(' + slotProps.row.departure_station.code + ')'
        }}</span>
    </template>

    <template v-slot:destination_station="slotProps">
      <span v-if="slotProps.row.destination_station === null" class="text-danger">--</span>
      <span v-else>{{
          slotProps.row.destination_station.name + '(' + slotProps.row.destination_station.code + ')'
        }}</span>
    </template>

    <template v-slot:document="{row: smgs}">
      {{smgs.document }}
    </template>

  </CustomTable>
</template>

<style scoped>

</style>